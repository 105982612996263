import React from 'react'
import BackLinks from './BackLinks'

const Services = () => {
    return (
        <div>

            <div className="container-fluid pt-5" id="service">
                <div className="container">
                    <div className="position-relative d-flex align-items-center justify-content-center">
                        <h1
                            className="display-1 text-uppercase text-white"
                            style={{ WebkitTextStroke: "1px #dee2e6" }}
                        >
                            Expertise
                        </h1>
                        <h1 className="position-absolute text-uppercase text-primary">
                            Core Expertise
                        </h1>



                    </div>
                    <div className="row pb-3">
                        <div className="col-lg-4 col-md-6 text-center mb-5">
                            <div className="d-flex align-items-center justify-content-center mb-4">
                                <i className="fa fa-2x fa-laptop-code service-icon bg-primary text-white mr-3" />
                                <h4 className="font-weight-bold m-0">Technology Transfer</h4>
                            </div>
                            <p>
                                Successfully Transferred Technology for conjugate vaccines such as Pneumococcal, Meningococcal,
                                H1N1,H5N1, Haemophilus-Influenzae B, Tetanus-Toxoid & Hepatitis-B.
                            </p>
                            <a
                                className="border-bottom border-primary text-decoration-none"
                                href=""
                            >
                                Read More
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 text-center mb-5">
                            <div className="d-flex align-items-center justify-content-center mb-4">
                                <i className="fa fa-2x fa-edit service-icon bg-primary text-white mr-3" />
                                <h4 className="font-weight-bold m-0">Facility Design</h4>
                            </div>
                            <p>
                                Providing end to end solutions to the challenges faced by Pharmaceutical, Biotech and vaccine
                                industry  in terms of Facility Design and Project Implementation, Manufacturing,
                                Product Development, Scale-up & optimization, Business Development Strategies,
                                Quality Management, Manpower Development and Intellectual Property Rights.

                            </p>
                            <a
                                className="border-bottom border-primary text-decoration-none"
                                href=""
                            >
                                Read More
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 text-center mb-5">
                            <div className="d-flex align-items-center justify-content-center mb-4">
                                <i className="fa fa-2x fa-search service-icon bg-primary text-white mr-3" />
                                <h4 className="font-weight-bold m-0">Audit and Inspection (GMP)</h4>
                            </div>
                            <p>
                                GMP and GLP audits and compliances for US-FDA, EU-GMP, S-FDA, TEVA, TGA, NPRA, ANVISA, CDSCO
                                and WHO as well as other domestic audits and inspections subjected to other third-party
                                and loan licensed manufacturers.
                            </p>
                            <a
                                className="border-bottom border-primary text-decoration-none"
                                href=""
                            >
                                Read More
                            </a>
                        </div>
                        <BackLinks />
                        {/* <div className="col-lg-4 col-md-6 text-center mb-5">
                            <div className="d-flex align-items-center justify-content-center mb-4">
                                <i className="fab fa-2x fa-apple service-icon bg-primary text-white mr-3" />
                                <h4 className="font-weight-bold m-0">Apps Development</h4>
                            </div>
                            <p>
                                Justo sit justo eos amet tempor amet clita amet ipsum eos elitr.
                                Amet lorem lorem lorem est amet labore eirmod erat clita
                            </p>
                            <a
                                className="border-bottom border-primary text-decoration-none"
                                href=""
                            >
                                Read More
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 text-center mb-5">
                            <div className="d-flex align-items-center justify-content-center mb-4">
                                <i className="fa fa-2x fa-search service-icon bg-primary text-white mr-3" />
                                <h4 className="font-weight-bold m-0">SEO</h4>
                            </div>
                            <p>
                                Justo sit justo eos amet tempor amet clita amet ipsum eos elitr.
                                Amet lorem lorem lorem est amet labore eirmod erat clita
                            </p>
                            <a
                                className="border-bottom border-primary text-decoration-none"
                                href=""
                            >
                                Read More
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 text-center mb-5">
                            <div className="d-flex align-items-center justify-content-center mb-4">
                                <i className="fa fa-2x fa-edit service-icon bg-primary text-white mr-3" />
                                <h4 className="font-weight-bold m-0">Content Creating</h4>
                            </div>
                            <p>
                                Justo sit justo eos amet tempor amet clita amet ipsum eos elitr.
                                Amet lorem lorem lorem est amet labore eirmod erat clita
                            </p>
                            <a
                                className="border-bottom border-primary text-decoration-none"
                                href=""
                            >
                                Read More
                            </a>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services