import React from 'react'

const About = () => {
    return (
        <div>
            <div className="container-fluid py-5" id="about">
                <div className="container">
                    <div className="position-relative d-flex align-items-center justify-content-center">
                        <h1
                            className="display-1 text-uppercase text-white"
                            style={{ WebkitTextStroke: "1px #dee2e6" }}
                        >
                            About
                        </h1>
                        <h1 className="position-absolute text-uppercase text-primary">
                            About Me
                        </h1>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 pb-4 pb-lg-0">
                            <img className="img-fluid rounded w-100" src="imgs/banner.jpg" alt="" />
                        </div>
                        <div className="col-lg-7">
                            <h3 className="mb-4"> Research Scientist CAR-T & Vaccines</h3>
                            <p>
                                Seasoned Scientist with 8+ Years of Cross-disciplinary research experience covering
                                Regulatory Audits and Compliances (WHO, US-FDA, EU-GMP, S-FDA, TGA, ANVISA,
                                NPRA, CDSCO & GLP) with wide experience for Technology Transfer to International
                                Clients (Germany, Russia, Egypt, Malaysia, Saudi-Arabia, Iran, Cuba & China) and Quality
                                Management System on vaccines/Pharmaceuticals/Biologics Vaccine industry.
                                Conceptualized and designed the establishment of affordable, scalable and robust
                                processes, ensuring the development of safe and Immunogenic high quality vaccines
                                covering both under-utilized as well as new vaccines of public health importance,
                                including COVID-19, Pneumococcal (PCV), Meningococcal (MCV), H1N1, H5N1,
                                Haemophilus-Influenzae B, Tetanus-Toxoid & Hepatitis-B vaccines.
                                Experienced in promoting vaccine regulation and pharmacovigilance, quality assurance and quality
                                monitoring systems.Collaborated for development and use of guidelines on vaccine
                                regulation, quality control/quality assurance of vaccines, Good Manufacturing Practices
                                and laboratory quality control of vaccines e.g. Licensing and Marketing Authorization
                                (MA), Emergency Use Authorization (EUA), Good Regulatory Practices (GRPs), Quality
                                Management Systems and Quality Risks Management. </p>
                            <div className="row mb-3">
                                <div className="col-sm-6 py-2">
                                    <h6>
                                        Name: <span className="text-secondary">Shrish Chandra Pandey</span>
                                    </h6>
                                </div>
                                <div className="col-sm-6 py-2">
                                    <h6>
                                        Birthday: <span className="text-secondary">October 15</span>
                                    </h6>
                                </div>
                                <div className="col-sm-6 py-2">
                                    <h6>
                                        Degree: <span className="text-secondary">Master</span>
                                    </h6>
                                </div>
                                <div className="col-sm-6 py-2">
                                    <h6>
                                        Experience: <span className="text-secondary">8+ Years</span>
                                    </h6>
                                </div>
                                <div className="col-sm-6 py-2">
                                    <h6>
                                        Phone: <span className="text-secondary">+91-9140087639</span>
                                    </h6>
                                </div>
                                <div className="col-sm-6 py-2">
                                    <h6>
                                        Email: <span className="text-secondary">shrish151092@gmail.com</span>
                                    </h6>
                                </div>
                                <div className="col-sm-6 py-2">
                                    <h6>
                                        Address:{" "}
                                        <span className="text-secondary">
                                            BSC BioNest BioIncubator (BBB)
                                            Regional Centre for Biotechnology
                                            NCR Faridabad Haryana- 121001
                                        </span>
                                    </h6>
                                </div>
                                <div className="col-sm-6 py-2">
                                    <h6>
                                        Freelance: <span className="text-secondary">Available</span>
                                    </h6>
                                </div>
                            </div>
                            <a className="btn btn-outline-primary mr-4">
                                Hire Me
                            </a>
                            <a className="btn btn-outline-primary">
                                Learn More
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About